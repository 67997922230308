@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200&display=swap');

body{
    
    font-family: 'Kanit', sans-serif !important;
    font-size: 15px;
    background-color:#F4F4F4 !important;
}

.app-card{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 20px;
    border-radius: 5px;
    background-color: #FFF;
}

.app-card-title{
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #637381;
}

.div-upload{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #EDEDED;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}
